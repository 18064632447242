<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="nav grid-icon">
      <b-link
        class="nav-link"
        @click="toggleVerticalMenuActive"
      >
        <b-img
          :src="appLogoImage"
          alt="logo"
        />
      </b-link>
    </div>
    <b-navbar-nav
      id="ez-root-menu"
      class="d-none d-lg-flex"
    >
      <b-nav-item
        v-for="(item, index) in navMenuItems"
        :key="index"
        href="#"
        @click="setRootRoute(index)"
      >
        {{ $t(item.title) }}
      </b-nav-item>
      <search-bar />
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center ml-auto">

      <courses-dropdown />
      <locale />
      <!-- <div class="icon-nav-act">
        <feather-icon
          class="item-icon-nav-act"
          icon="HelpCircleIcon"
          size="22"
          @click="clickHelp"
        />
        <feather-icon
          class="item-icon-nav-act"
          icon="RefreshCwIcon"
          size="22"
          @click="clickRefresh"
        />
      </div> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BImg, BNavbarNav, BNavItem, BNavbarBrand,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import router from '@/router'
import store from '@/store'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import CoursesDropdown from './components/CoursesDropdown.vue'

export default {
  components: {
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,
    BNavItem,
    BNavbarBrand,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    CoursesDropdown,
    ToastificationContent,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    // Long HQ - 09/11/2021 - Lưu index mảng link để hiện thị các sub item của nó ở menu trái, đồng thời chuyển hướng đến menu đầu tiên của sub item.
    setRootRoute(index) {
      localStorage.setItem('rootRoute', index)
      store.commit('verticalMenu/UPDATE_ROOT_ROUTE', index)
      // store.commit('rootRoute', index)

      // this.router.push({ name: navMenuItems[index].children})
    },
    clickHelp() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'You have click on Help',
        },
      })
    },
    clickRefresh() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'You have click on Refresh',
        },
      })
    },
  },
  setup(props) {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      navMenuItems,
    }

    // return { navMenuItems }
  },
}
</script>
